import React from 'react'
import { graphql } from 'gatsby'
import Meta from '../components/meta'
import Layout from '../components/layout'
import Breadcrumb from '../components/breadcrumb'

export default ({data, pageContext, location}) => {
  const site = data.site
  const breadcrumbList = [{str: 'トップ', path: '/'}, {str: 'Tech libについて'}]

  return (
    <Layout location={location}>
      <div style={{ background: '#fff' }}>
        <Meta
          site={site}
        />
        <div className="wrapper">
          <Breadcrumb breadcrumbList={breadcrumbList}/>
          <article className="article">
            <h1>Tech libについて</h1>
            <p>Tech libは「WEB開発の現場で求められる基本的な知識を集約する場所を学習者の方に向けて作りたい」という想いからスタートしたWEBサイトです。</p>
            <p>主に以下のような2つの課題を解消すべく日夜努力しています。</p>
            
            <h2>情報にたどり着くのが難しい</h2>
            <p>
             「WEBの仕組み」「データベース」「オブジェクト指向」「デザインパターン」など、
              WEB開発を行う上で求められる知識は数多くありますが、それらは書籍やWEB等に点在している為に自分で適切な情報源を吟味してたどり着く必要があります。
              これを自力でやるのは、ある程度の見通しが立つまではかなり困難なことではないでしょうか。
            </p>

            <p>
              また、昨今は誰でも気軽に技術的な情報の発信を行えるようになりました。<br/>
              それ自体は歓迎すべきカルチャーであると感じる反面、理解度や解釈の違いが様々な為に
              中には学習者に誤った知識を植えつけてしまう情報も少なくないと感じています。
            </p>

            <p>
              以上のような背景により「求めている妥当性のある情報」に行き着くハードルが年々高まっていると言えるでしょう。
            </p>
            <p>
              Tech libは学習者が「知らない言葉に出会ったらここを見ればまず足掛かりになる！」と思ってもらえるような場所になりたいと考えています。
            </p>

            <h2>情報を理解するのが難しい</h2>

            <p>
              ある程度それらしいWEBページへたどり着いても、文章から具体的な意味を獲得できずに
              苦労することはないでしょうか。また、表現が難し過ぎて読むのを断念したことはないでしょうか。
              システム開発やコンピュータに関係する言葉は深い背景知識を必要とするものであったり
              プログラミングのコードにしないと難しかったりするものが数多くあります
              （例えば Wikipedia は大変有用なWEBサイトではありますが、言葉の説明だけで理解するのがかなり困難だと感じることがあると思います）。
            </p>

            <p>そのような課題意識から、Tech libでは以下のようなことを行っています。</p>
            
            <ul>
              <li>「概念」は動画で伝える</li>
              <li>実際にソースコードやブラウザを操作するところを通して「具体的にどういうものか」を伝える</li>
              <li>利用した<a href="https://github.com/CircleAround/pgonline" target="_blank" rel="noreferrer">ソースコードなどは動く形で公開</a>
                しておき、試せるようにしておく
              </li>
            </ul>
            
            <p>
              以上のような工夫に加えて、<a href="https://circlearound.co.jp" target="_blank" rel="noreferrer">運営者のサークルアラウンド社</a>
              ではプログラマーのトレーニング事業やシステムの受託開発を行っている経験から
              「よくある誤解」を記したり「実際の開発でよく利用されるもの」をピックアップするなど、
              無機質な言葉の説明だけに終わらないように努めています。
            </p>

            <p>ぜひ辿り着いた情報から実際に手を動かして試して、本当の意味での理解を深めてください。</p>

            <p>
              Tech libが多くの学習者の方の一助になれば幸いです。
            </p>
          </article>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AboutQuery {
    site {
      siteMetadata {
        title
        tagline
        author
        host
        description
      }
    }
  }
`

