import React from 'react';
import styles from './breadcrumb.module.css'

export default ({ breadcrumbList }) => {
  const generateList = () => {
    return breadcrumbList.map((breadcrumb, index)=>{
      let isLast = ((index+1) === breadcrumbList.length)
      let separation = isLast ? '' : <span className=
      {styles.separation}></span>
      let str = <span itemProp="name">{breadcrumb.str}</span>
      str = isLast ? str : <a href={breadcrumb.path} itemProp="item">{str}</a>
      return (
        <>
          <li key={breadcrumb.str} className={styles.item} itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
            {str}
            <meta itemProp="position" content={index+1} />
          </li>
          {separation}
        </>
      )
    })
  }

  return (
    <ol className={`breadcrumbs ${styles.breadcrumbs}`} itemScope itemType="http://schema.org/BreadcrumbList">
      {generateList()}
    </ol>
  )
}
